import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/deerlick.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-deerlick',
  templateUrl: './deerlick.component.html',
  styleUrls: ['./deerlick.component.sass']
})
export class DeerlickComponent {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;

}
