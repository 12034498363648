<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/DeerLick-FinalLRGBHa-65.dzi', 'assets/astro-img/DeerLick-FinalLRGB-65.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/DeerLick_Annotations/'"
                    [imageWidth]="6500"
                    [imageHeight]="3677" [imageScale]="0.713"
                    [imageVersions]="['lrgbha', 'lrgb']"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'L', brand: '', exposures: [{ count: 1178, total: 492900 }] },
                                        { filter: 'R', brand: '', exposures: [{ count: 341, total: 119460 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 292, total: 106440 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 258, total: 100740 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 809, total: 1176300 }] }]"
                    [nights]="{ count: 72, start: '2022-08-23', end: '2024-10-08' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['AG Optical FA12 F5', 'Askar 130PHQ', 'Askar 151PHQ', 'Sky-Watcher Esprit 150ED', 'Stellarvue SVX130T', 'Takahashi FSQ-106EDX4', 'Takahashi TOA-150', 'TS-Optics 200mm ONTC f4.5 Newtonian', 'TS-Optics 200mm ONTC f4 Newtonian', 'TS-Optics 250mm ONTC f4 Newtonian', 'TS-Optics ED 102mm f7', 'TS-Optics Photoline 140mm f6.5'],
                                    cameras: ['Moravian Instruments C3-61000EC Pro', 'QHYCCD QHY600PH M', 'RisingCam IMX571', 'ZWO ASI1600MM', 'ZWO ASI2600MM Pro', 'ZWO ASI6200MM Pro'] }">
</app-astro-photo>